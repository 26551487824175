import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_alert = _resolveComponent("el-alert");

  var _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createBlock(_component_el_row, {
    align: "middle",
    justify: "center"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_alert, {
        closable: false,
        style: {
          "padding": "24px",
          "text-align": "left"
        },
        title: "Error",
        type: "error",
        center: false,
        description: $props.msg,
        "show-icon": ""
      }, null, 8, ["description"])];
    }),
    _: 1
  });
}