import HttpClient from './HttpClient';

export default class UserUpgrade {

    constructor (upgrade = null) {
        this.id = null;
        this.user_id = null;
        this.fs_path = null;
        this.pid = null;
        this.price = null;
        this.name = null;
        this.issued_by = null;
        this.used = null;

        if (upgrade && typeof upgrade === 'object') {
            for (const k in upgrade) {
                this[k] = upgrade[k];
            }
        }
    }

    /**
     * Fetches the current user's user-specific upgrades
     */
    static async fetch (page = null) {
        const client = new HttpClient({ path: '/laravel/rest/products/user-upgrades', query: { page } });
        const resp = await client.get();
        
        if (!(resp && resp.status && resp.status === 200)) {
            throw new Error(resp.status);
        }
        
        if (!resp.data) {
            throw new Error('Error fetching user upgrades');
        }

        const { data, last_page, total } = resp.data;
        
        return {
            data: data.map(u => new UserUpgrade(u)),
            page,
            last_page,
            total
        };
    }
}